import { ALink, CoverContainer } from '../../../components';
import { IMenu, menuList } from '../../../menus';
import './QuickLinks.scss';

import donateqrcode from "../../../../../assets/images/donate.png";

export const QuickLinks = () => {
 return (
    <CoverContainer className="quick-links" style={{paddingTop: '2rem', paddingBottom: '2rem'}}>
        <div className="footer-nav-links">
                <h1>Quick Links</h1>
            <div className="f-n-lks">
                <div className="links">
                    {menuList.map((menu: IMenu, i) => {
                        if(menu.url) {
                            return (
                                <div key={i} className='footer-nav-link'>
                                    <ALink url={menu.url}><span>{menu.name}</span></ALink>
                                </div>
                            )
                        } else {
                            return (menu.children as IMenu[]).map((chn, idx) => {
                                return (
                                    <div key={idx} className='footer-nav-link'>
                                    <ALink url={chn.url}><span>{chn.name}</span></ALink>
                                    </div>
                                )
                            })
                        }
                    })}
                </div>
                <div className='qrcode-section'>
                    <h2>Donation Qr code</h2>
                    <div>
                        <img src={donateqrcode} alt='donate qr code'/>
                    </div>
                </div>
            </div>
        </div>
        {/* <div className="footer-social-media-links">

        </div> */}
    </CoverContainer>
 );
}