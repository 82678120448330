import { Children } from "react";
import "./CardListGrid.scss";

export const CardListGrid = ({...props}) => {
    const { children } = props;

    return (
        <div className="card-list-grid">
            {Children.map(children, (child, i) => {
                return (
                    <div className="grid-card">
                        {child}
                    </div>
                )
            })}
        </div>
    )
}