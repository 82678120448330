import { cloneElement, useEffect, useState } from 'react';
import './Field.scss'

type ITxtAreaEvt = React.FormEvent<HTMLTextAreaElement>;
type IEvt = React.FormEvent<HTMLInputElement>;



// interface ITextField extends IField { 
//     changeHandler: (x: IInputResponse) => void;
// }


// interface INumberField extends IField { 
//     changeHandler: (x: IInputResponse) => void;
// }


// interface ITextAreaField extends IField { 
//     changeHandler: (x: IInputResponse) => void;
// }

export const fieldTypes = {
    text: 'text',
    password: 'password',
    email: 'email',
    number: 'number',
    textarea: 'textarea'
}

export interface IField {
    name: string;
    type?: string;
    init?: string;
    label?: string;
    required?: boolean;
}

export interface IInputResponse {
    name: string; 
    value: string;
}

interface IFieldProps {
    field: IField;
    init?: any;
    changeHandler: (x: IInputResponse) => void;
    formName: string;
}

export const Field: React.FC<IFieldProps> = ({field, init, formName, changeHandler }) => {
    const [value, setValue] = useState(init);

    const fieldName = field.name.toLowerCase();
    const fieldId = `${formName}_${fieldName}`.toLowerCase();
    
    const handler = (e: any) => {
        const val = e.currentTarget.value;
        setValue(val);
        changeHandler({name: fieldName, value: val});
    }
    let input = <input />
    switch(field.type) {
        case fieldTypes.number:
            input = <input id={fieldId} name={fieldName} value={value} onChange={handler}/>;
            break;
        case fieldTypes.textarea:
            input = <textarea id={fieldId} name={fieldName} value={value} onChange={handler} />;
            break;
        case fieldTypes.password:
            input = <Password id={fieldId} name={fieldName} value={value} onChange={handler} />
            break;
        default:
            input = <input id={fieldId} name={fieldName} value={value} onChange={handler} />;
    }

    useEffect(() => {
        setValue(init);
    }, [init])
    
    return(
        <div className="text-field">
            {!!field.label ? 
                (<label htmlFor={fieldId}>{field.label} {field.required && <small>*</small>}</label>) : 
                (<label htmlFor={fieldId}>{(fieldName as any).capitalizeFirstLetter()} {field.required && <small>*</small>}</label>)}
            {input}
        </div>
    )
}



const Password = ({...props}) => {
    return (
        <span >
            <input {...props} type="password" />
        </span>
    )
}