import { forwardRef} from 'react';
import ReactDOM from 'react-dom';
import { Close } from '../icons';
import { Search } from '../search';
import styles from "./modal.module.scss";
import { useModalFunctions } from './useModalFunctions';

interface Props {
    children?: any;
}

export const SearchModal = forwardRef<any, Props>((_, ref) => {
    const {display, close} = useModalFunctions({ref});
    if(!display) return null;

   
    return ReactDOM.createPortal(
       <div className={styles.AwModal}  role="dialog" aria-labelledby="dialog-title">
           <div className={styles.AwModalBackdrop} onClick={close} />
           
           <div className={styles.AwModalSearchContent}>
               <div className={styles.AwModalSearchContentBody}>
               <div className={styles.AwModalSearchContentCloseArea}>
                    <Close clicked={close} className="accent" />
               </div>
               <Search />
               <p>Press <span>'Enter/Return'</span> to search</p>
               </div>
           </div>
       </div>
        , document.getElementById("search-modal-root")  as HTMLElement)
   })