import React from 'react';
import { CardFrame } from '../CardFrame';
import "./ColorHeaderCard.scss";

interface IColorHeaderCard {
    heading: string;
    minContent: string;
    detailUrl: string;
    children?: any;
}
export const ColorHeaderCard: React.FC<IColorHeaderCard> = ({heading, minContent, detailUrl, ...props}) => {
    const headerArr = heading.split(' ');
    const shouldBeColored = headerArr.length > 1;

    return (
        <CardFrame cls="color-header-card" minContent={minContent} detailUrl={detailUrl}>
            <h1 className='title'>{shouldBeColored ? (<>
                <span>{headerArr[0]} </span> {headerArr[1]}
            </>): heading}
            </h1>
            {props.children}
        </CardFrame>
    )
}