import { AppProvider, AuthProvider } from "./context"
import { EntryOutlet } from "./EntryOutlet"
import { Router } from "./shared"

export const Entry = () => {
    return (
        <Router>
            <AppProvider>
                <AuthProvider>
                    <EntryOutlet />
                </AuthProvider>
            </AppProvider>
        </Router>
    )
}


