import { CardFrame } from "../CardFrame";
import "./BasicCard.scss";


interface Props {
    heading: string;
    minContent: string;
    detailUrl: string;
    children?: any;
}

export const BasicCard: React.FC<Props> = ({heading, minContent, detailUrl, ...props}) => {
    return (
        <CardFrame cls="basic-card" minContent={minContent} detailUrl={detailUrl}>
            <h1 data-text={heading.toUpperCase()}>{heading}</h1>
            <div>{props.children}</div>
        </CardFrame>
    )
}



