import { Footer, Routes, setExtensionMethods, Header } from "./shared"
import './EntryOutlet.scss';

export const EntryOutlet = () => {
    setExtensionMethods();
    
    return (
        <div className="entry-outlet">
            <Header />
            <main>
                <Routes />
            </main>
            <Footer />
        </div>
    )
}