import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { Arrow, Close } from '../../components';
import { IMenu, menuList } from '../../menus';
import './MobileMenu.scss';

interface IMenuSetting {
    menus:IMenu[]; 
    show: Boolean;
}
export const MobileMenu = () => {
    const body = (document.querySelector("body") as HTMLElement);
    const content = (document.getElementById('content-root') as HTMLElement);
    const navigate = useNavigate();
    const [display, setDisplay] = useState(false);

    const [mainMenus] = useState<IMenu[]>(menuList);

    const [dropdown, setDrop] = useState<IMenuSetting>({menus: [], show: false});

    const setDropdownMenus = (menus: IMenu[]) => {
        setDrop(state => ({...state, menus: menus, show: true}));
    }
    const showHideDropdownMenu = () => {
        setDrop(state => ({...state, show: !state.show}));
    }
    const resetMenus = () => {
        setDrop(state => ({...state, show: false}));
    }

    const reOrganizePage = () => {
        body.style.overflow = 'hidden';
        content.style.transition = 'all 0.3s linear';
        const timeout = setTimeout(() => {
            content.style.filter = 'blur(5px)';
            clearTimeout(timeout);
        }, 50);
    }
    const resetPage = () => {
        body.removeAttribute('style');
        content.removeAttribute('style');
    }
    const open = () => {
        setDisplay(true);
        reOrganizePage()
    }
    const close = () => {
        resetPage();
        resetMenus();
        setDisplay(false);
    }
    
    const handleNavigate = (url: string) => {
        navigate(url);
        close();
    }
    

    if(!display) return (
        <div className="mobile-menu-btn-wrapper">
            <button onClick={open} className="btn-menu">Menu</button>
        </div>
    )

    return createPortal(
                <div className="mobile-menu-wrapper show">
                    <div className="backdrop" onClick={close}></div>
                    <div className="content">
                    <div className="header">
                        <div className="left-btns">
                        {dropdown.show && (
                            <Arrow dir="left" clicked={() => showHideDropdownMenu()}>
                                <div>Back</div>
                            </Arrow>
                        )}
                        </div>
                        <Close clicked={close}/>
                    </div>
                    <div className="menus" style={{overflowY: 'scroll'}}>
                        {!dropdown.show && <MenuList 
                                                menus={mainMenus} 
                                                setDropdown={setDropdownMenus} 
                                                navigate={handleNavigate}/>}
                        {dropdown.show && <MenuList 
                                                menus={dropdown.menus} 
                                                setDropdown={setDropdownMenus}
                                                navigate={handleNavigate}/>}
                    </div>
                    </div>
                </div>,
                (document.getElementById('mobile-menu-root') as HTMLDivElement)
    )
}


const MenuList: React.FC<{menus: IMenu[]; navigate: (v:string) => void; setDropdown: (menus: IMenu[]) => void; }> = ({menus, navigate, setDropdown}) => {
    return (<>
        {menus.map((menu, i) => {
            return (
                <MenuItem menu={menu} key={i} navigate={navigate}  setDropdown={setDropdown} />
            )
        })}
    </>)
}

const MenuItem: React.FC<{menu: IMenu; navigate: (v:string) => void; setDropdown: (menus: IMenu[]) => void; }> = ({menu, navigate, setDropdown}) => {
    const onlyDropdown = menu.children && !menu.url;
    return (
        <div className="menu">
            {onlyDropdown ? (
                <div className="mobile-menu-link" onClick={() => setDropdown(menu.children)}>
                    <span>{menu.name} </span>
                    <span><Arrow dir="right" /></span>
                </div>
            ) : (
                <div>
                    {!!menu.url ? <span className="mobile-menu-link" onClick={() => navigate(menu.url)}>{menu.name} </span> : <span>{menu.name} </span> }
                    {menu.children && <span onClick={() => setDropdown(menu.children)}><Arrow dir="right" /></span>}
                </div>
            )}
        </div>
    )
}








// const RemoveComponentFromDOM = ({...props}) => {
//     const ref = useRef(props.show)// false to begin
//     const [state, setState] = useState(props.show);

//     function remove() {
//         ref.current = true;
//         setState(false);
//     }
    
//     if(!props.show && ref.current) {
//         // trying to close it
//         // setTimeout(remove, 6000)
//     } else if (props.show) {
//         ref.current = true;
//     }

//     // console.log('ref', ref.current);
//     // console.log('current', props.show);
//     useEffect(() => {
//         // if(props.show) 
//         setState(props.show);
//     }, [props.show])


//     if (!state) return null;
//     return props.children;
// }


// const useScheduleDOMDelete = () => {
//     const [state, setState] = useState({scheduled: false, remove: false});

//     const schedule = () => {
//         setState(s => ({...s, scheduled: true}));
//     }

//     const remove = () => {
//         setState(s => ({...s, remove: true}));
//     }

//     useEffect(() => {
//         let timeoutid:any;
//         if (state.scheduled) {
//             console.log('useeffect called')
//             timeoutid = setTimeout(remove, 6000)
//         }
//         return () => {
//             if (timeoutid) clearTimeout(timeoutid);
//         }
//     }, [state.scheduled])

//     return {scheduling: state, schedule}
// }