import { urls } from "../../menus"
import { ALink } from "../link"

export const ContentWithContact = ({...props}) => {
    return (
        <div>
            {props.children}
            <ALink url={urls.contact}>Get In Tourch</ALink>
        </div>
    )
}