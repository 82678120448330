// import { Route, Routes as RRDRoutes } from "react-router-dom"
import { AppRoutes, Route } from "../RoutingModules"
import { LandingPage } from "../../../landing"
import { lazy, Suspense } from "react"
import { urls } from "../../menus";

function getComponent(comp: string) {
    return lazy(() => import('../../../pages').then((x:any) => {
        return  {default: x[comp]};
    }))
}

const Vision = getComponent('WhoWeAre');
const Mission = getComponent('WhatWeDo');

const Support = getComponent('Support');
const SupportLanding = getComponent('SupportLanding');
const ResourceShare = getComponent('ResourceShare');
const TimeShare = getComponent('TimeShare');
const Donation = getComponent('Donation');
const Monetary = getComponent('Monetary');
const Material = getComponent('Material');


const Sports = getComponent('Sports');
// const SportsLandingPg = getComponent('SportsLandingPg');
// const Teams = getComponent('Teams');
// const Players = getComponent('Players');
// const Tournaments = getComponent('Tournaments');
// const Tryout = getComponent('Tryout');




const Contact = getComponent('Contact');
const NoticeBoard = getComponent('NoticeBoard');




const LifeTells = getComponent('LifeTells');
const Donated = getComponent('Donated');
const AboutUs = getComponent('AboutUs');


const PageNotFound = getComponent('PageNotFound');


export const Routes = () => {
    return (
        <Suspense fallback={<div />}>
            <AppRoutes>
                <Route path={urls.home} element={<LandingPage/>} />
                
                {/* lazy routes */}
                <Route path={urls.vision} element={<Vision />} />
                <Route path={urls.mission} element={<Mission />} />

                <Route path={urls.support.base} element={<Support />}>
                    <Route path={urls.support.base} element={<SupportLanding />} />
                    <Route path={urls.support.donation} element={<Donation />} />
                    <Route path={urls.support.monetary} element={<Monetary />} />
                    <Route path={urls.support.material} element={<Material />} />
                    <Route path={urls.support.resourceshare} element={<ResourceShare />} />
                    <Route path={urls.support.timeshare} element={<TimeShare />} />
                </Route>

                {/* <Route path={urls.sports.base} element={<Sports />}>
                    <Route path={urls.sports.base} element={<SportsLandingPg />} />
                    <Route path={urls.sports.teams} element={<Teams />} />
                    <Route path={urls.sports.players} element={<Players />} />
                    <Route path={urls.sports.tournaments} element={<Tournaments />} />
                    <Route path={urls.sports.tryout} element={<Tryout />} />
                </Route> */}
                <Route path={urls.sports.base} element={<Sports />} />


                <Route path={urls.lifeTells} element={<LifeTells />} />
                <Route path={urls.donated} element={<Donated />} />
                {/* <Route path={urls.aboutus} element={<AboutUs />} /> */}
                <Route path={urls.contact} element={<Contact />} />
                <Route path={urls.noticeboard} element={<NoticeBoard />} />


                <Route path={'/*'} element={<PageNotFound />} />
            </AppRoutes>
        </Suspense>
    )
}