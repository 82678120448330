interface Props {
   className: string;
   children: any;
   style?: any
}
export const CoverContainer: React.FC<Props> = ({className, style = {}, children}) => {
 return (
    <div 
         className={`cover-container ${className ? className : ''}`} 
         style={{...style, ...{width: '100%'}}}>
      {children}
   </div>
 );
}