import React from 'react';
import styles from "../carousel.module.css";

interface Props {
    count: number;
    slide: number;
    handle: (x: number) => void;
}

export const CarouselSlideIndicators: React.FC<Props> = ({count, slide, handle}) => {
    return (
        <div className={styles.CarouselIndicators}>
            {Array.from({length: count}).map((v, i) => {
                return <div key={i} className={`${styles.CarouselIndicator} ${slide === i ? styles.CarouselIndicatorActive : ''}`} onClick={() => handle(i)}></div>
            })}
        </div>
    );
};
