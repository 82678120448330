import React from 'react';
import styles from "../carousel.module.css";

interface Props {
    arrow: any;
    direction?: string;
    handle: () => void;
}
export const CarouselSlideBtn: React.FC<Props> = ({arrow, direction, handle}) => {
    return (
        <button 
            className={`${styles.Btn} 
            ${direction}`} 
            onClick={handle}>
                {arrow}
        </button>
    );
};
