import React from 'react';
import { ITestimony } from '../testimonies';


export const Testimonial: React.FC<ITestimony> = ({name, message}) => {
    // if(!name || !message) return null;
    return (
        <div>
            <h3>- {name} -</h3>
            <p>
                {message}
            </p>
        </div>
    );
};
