import { ColorHeaderCard, CoverContainer, urls } from '../../../shared';
import './VisionIntro.scss'

export const VisionIntro = () => {
    return (
        <CoverContainer className="vision-intro">
            <ColorHeaderCard heading='Keep Dreaming' minContent='' detailUrl={urls.vision}>
                {/* heading - We Dream */}
                Can you imagine a world where people are not judged for the color of their skin?

A world where been different is okay?

A world where we are all treated the same way base on basic human needs and at the same time treated uniquely?

That is what we see through the lens of formal and informal education.
            </ColorHeaderCard>
        </CoverContainer>
    );
};