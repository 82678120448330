import { useState, useEffect } from 'react';

export const usePageScroll = (update?: (x:any) => void)  => {
    const [scroll, setScroll] = useState({y: 0});

    function handle() {
      const v = {y: window.pageYOffset};
      if(update) return update(v);
      setScroll(v);
    }
  
    useEffect(() => {
    //   function watchScroll() {
    //   }
      // watchScroll();

        window.addEventListener("scroll", handle);
      return () => {
        window.removeEventListener("scroll", handle);
      };
    }, []);
  
    return { scroll }
};




