import { CoverContainer, SocialMediaLinks } from "../shared";
import { Banner, ContackLink, MissionIntro, Testimonials, VisionIntro } from "./components";

import "./LandingPage.scss"


export const LandingPage = () => {
    return (
        <CoverContainer className="landing-pg">
            <Banner />
            <MissionIntro />
            <VisionIntro />
            <Testimonials />
            <SocialMediaLinks />
            {/* <ContackLink /> */}
        </CoverContainer>
    )
}


