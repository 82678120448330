import "./Close.scss";

interface Props {
    clicked: () => void;
    className?: string; 
}
export const Close: React.FC<Props> = ({clicked, className = 'regular'}) => {
    return (
        <div onClick={clicked} className={`icon close ${className}`}>
            <span className="close-icon"></span>
        </div>
    )
}