import { ALink, urls } from "../../shared";

export const Banner = () => {
 return (
    <div className="banner">
        <div className="content">
            <h1>Educating our <br/> communities through sports</h1>

            <p className="cover-summary">
                Have you got a life experience to share? Can you spare 30 mins per week/month/yr to help?
                We can always use your help.
            </p>
            <div className="v-btn-div">
                <ALink className="btn-volunteer" url={urls.support.base}>Volunteer Now</ALink>
            </div>
        </div>
    </div>
 );
}