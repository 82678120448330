import React from "react";
import { ReadMore } from "../readmore";

import "./CardFrame.scss";


interface ICardFrame {
    cls: string;
    minContent: string;
    detailUrl: string;
    children?: any;
}
export const CardFrame: React.FC<ICardFrame> = ({cls, minContent, detailUrl, ...props}) => {
    return (
        <div className={`card-frame ${cls}`}>
            {/* header and other children */}
            <div>{props.children}</div>
            {minContent && <WithLines><small><strong>{minContent}</strong></small></WithLines>}
            {detailUrl && <ReadMore url={detailUrl} />}
        </div>
    )
}

const WithLines = ({...props}) => {
    return (
        <div className="with-lines">
            <div></div>
            {props.children}
            <div></div>
        </div>
    )
}
