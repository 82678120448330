// export const menuList = [
//     "Soccer",
//     "Educational Support",
//     "Life Tells"
// ]


export interface IMenu {
    url: string;
    name: string;
    children: IMenu[] | any;
}

export const urls = {
    home: "/",
    vision: "/vision",
    mission: "/mission",
    sports: {
        base: '/sports', 
        // tournaments: '/sports/tournaments', 
        // teams: '/sports/teams', 
        // tryout: '/sports/tryout', 
        // players: '/sports/players'
    },
    education: {
        base: '/education',
        tuition: '/education/tuition'
    },
    lifeTells: '/lifetells',
    donated: '/donated',
    support: {
        base: '/support',
        timeshare: '/support/timeshare',
        resourceshare: '/support/resourceshare',
        donation: '/support/donation',
        material: '/support/material',
        monetary: '/support/monetary'
    },
    contact: '/contactus',
    noticeboard: '/notification',
    // aboutus: '/aboutus'
}

export const menuList: IMenu[] = [
    {url: '/', name: 'Home', children: null},
    {url: '/vision', name: 'Vision', children: null},
    {url: '/mission', name: 'Mission', children: null},
    {url: urls.support.base, name: 'Support', children: null },
    // {url: '', name: 'Support', children: [
    //     // {url: urls.support.base, name: 'Support', children: null},
    //     {url: urls.support.monetary, name: 'Money Donation', children: null},
    //     {url: urls.support.material, name: 'Material Donation', children: null},
    //     {url: urls.support.timeshare, name: 'Time Share', children: null },
    //     {url: urls.support.resourceshare, name: 'Resource Share', children: null },
    //     // {url: urls.support.donation, name: 'Donation', children: null}
    // ]},
    {url: urls.sports.base, name: 'Sports', children: null},
    // {url: '', name: 'Sports', children: [
    //     {url: urls.sports.base, name: 'Sports', children: null},
    //     {url: urls.sports.tournaments, name: 'Tournaments', children: null},
    //     {url: urls.sports.teams, name: 'Teams', children: null},
    //     {url: urls.sports.players, name: 'Players', children: null},
    //     {url: urls.sports.tryout, name: 'Tryout', children: null}
    // ]},
    {url: urls.lifeTells, name: "Life Tells", children: null},
    // {url: urls.aboutus, name: 'About Us', children: null},
    {url: urls.contact, name: 'Contact Us', children: null},
    {url: urls.noticeboard, name: 'Notice Board', children: null}
];
