export interface ITestimony {
    name: string;
    message: string;
}

export const testimonies: ITestimony[] = [
    {
        // name: 'Haadi Awudu', 
        name: 'Anonymous', 
        message: `Leapstep paid my final year tuition for me 
    to get my teaching diploma in 
    the northern part of Ghana - West Africa.`},
    {
        // name: 'Patrick Oppong',
        name: 'Anonymous',
        message: `The owner helped me with my tuition fee when I needed it. 
        I still had to drop out eventually because there was other financial obligations I couldn't 
        afford`
    }
]