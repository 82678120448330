import { createContext, useContext, useState } from "react"
interface IObject {
    [x: string]: any
}
interface IMobileMenuState {
    isMenuShowing: boolean;
    hideMenu: () => void;
    showMenu: () => void;
}
interface IMobile extends IMobileMenuState {
}

interface IUser {
    username: String;
}
interface IAuthSettings {
    user: IUser
}

interface IPage {
    scroll: {y: number; x: number; setScroll: (v: IObject) => void;}
}
interface IAppSettings {
    mobile: IMobile;
    page: IPage;
}
const AppContext = createContext<IAppSettings>({} as IAppSettings)
const AuthContext = createContext<any>(null)



export const AuthProvider = ({...props}) => {
    const {children} = props;
    const [user, setUser] = useState<IAuthSettings>({ user: {username: "John"}})

    const login = ({}) => {
        setUser(user)
    }

    const logout = () => {
        setUser({user: {username: ""}})
    }
    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    )
}


export const AppProvider = ({...props}) => {
    const {children} = props;

    const mobileMenUpdate = (v: boolean) => {
        updateSettings(state => ({...state, mobile: {...state.mobile, isMenuShowing: v}}));
    }
    const setScroll = (x: IObject) => {
        updateSettings(state => ({...state, page: {...state.page, scroll: {...state.page.scroll, ...x}}}));
    }
    const showMobileMenu = () => mobileMenUpdate(true);
    const hideMobileMenu = () => mobileMenUpdate(false);

    const [settings, updateSettings] = useState<IAppSettings>({ 
        mobile: { isMenuShowing: false, showMenu: showMobileMenu, hideMenu: hideMobileMenu}, 
        page: { scroll: {y: 0, x: 0, setScroll}}
    })
    


    return (
        <AppContext.Provider value={{ mobile: settings.mobile, page: settings.page }}>
            {children}
        </AppContext.Provider>
    )
}



export const useAuth = () => {
    return useContext(AuthContext)
}

export const useAppSettings = () => {
    return useContext(AppContext)
}


