import { CoverContainer } from "../../../components";
import "./Reg.scss";



export const Reg = () => {
    return (
        <CoverContainer className="reg" style={{paddingTop: '2rem', paddingBottom: '2rem'}}>
            <small>
            <p>Leapstep Foundation is a registered 501(c)3 nonprofit organization. 
            <a href="/support"> All donations are tax deductible in full or in part. </a>
            </p>
            <p>&copy; {new Date().getFullYear()} by Leap Step Foundation. All Rights Reserved.</p>
            </small>
        </CoverContainer>
    )
}