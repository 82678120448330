import React from 'react';


import { urls } from "../../menus"
import { ALink } from "../link"

export const ContentWithNoticeBoard = ({...props}) => {
    return (
        <div>
            {props.children}
            <ALink url={urls.noticeboard}>Check our notice board</ALink>
        </div>
    )
}