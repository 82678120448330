import { ColorHeaderCard, CoverContainer } from '../../../shared';

export const MissionIntro = () => {
    return (
        <CoverContainer className="mission-intro">
            <ColorHeaderCard
                heading="Leapstep Foundation"
                minContent=''
                detailUrl='/mission'
                 >
                <p>We are all about making education fun and accessible to everyone.</p>
                <p>Education, formal and informal, we believe is the way to make the world a better place for current and future generation.</p>
                <p>Unfornately education in most part of Africa is inaccessible or it's more of a privilege only for the rich minority.</p>
            </ColorHeaderCard>
        </CoverContainer>
    );
};