// interface IString {

import emailjs, { EmailJSResponseStatus } from "@emailjs/browser";
import { IField } from "./forms";

// }
// interface String extends IString {
//     isEmpty: () => boolean;
//     capitalizeFirstLetter: () => string;
// }
export const setExtensionMethods = () => {

    const type = (String.prototype as any);

    type.isEmpty = function () {
        return this.length === 0;
    };

    type.capitalizeFirstLetter = function() {
        if (this.isEmpty()) return '';
        return `${this[0].toUpperCase()}${this.slice(1).toLowerCase()}`
    }
}

export const checkFormRequiredValidity = (values: {[x: string]: any;}, fields: IField[]) => {
    let output:string[] = [];
    for (let i = 0; i < fields.length; i++) {
        if(fields[i].required) {
            if(!values[fields[i].name.toLowerCase()]) {
                // it doesn't have a value
                 output.push("Please answer all required fields!");
                 return output;
            }
        }
    }
    return output;
}

export const getPaymentUrl = () => {
    return "https://www.paypal.com/donate/?hosted_button_id=QLG22EHDTNU38";
}

export interface IEmailForm {
    user_name: string;
    user_email: string;
    subject: string;
    message: string;
    payment_method?: string;
}
const sendEmail = async (funcAsync: any, values: any) => {
    try {
        const res: EmailJSResponseStatus =  await funcAsync(
            `${process.env.REACT_APP_TOURNAMENT_PAYMENT_EMAIL_SERVICE}`, 
            `${process.env.REACT_APP_NGO_EMAIL_TEMPLATE_SERVICE}`, 
            values,
            `${process.env.REACT_APP_EMAIL_SERVICE_PUBLIC_KEY}`);
            
        if(res.status < 400) {
            return {success: true, message: ''}
        } else {
            return {success: false, message: 'Email sending failure! Please try again later!'}
        }
    } catch (error: any) {
        return {success: false, message: error || 'Email sending failure! Please try again later!'}
    }
};

export const sendFormEmailAsync = async (form: HTMLFormElement) => { 
    return await sendEmail(emailjs.sendForm, form);
}
export const sendEmailAsync = async (values: IEmailForm) => {
    return await sendEmail(emailjs.send, values);
};
