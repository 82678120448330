import { NavLink } from 'react-router-dom';
import './ALink.scss'

interface Props {
   url: string;
   className?: string;
   target?: any;
   ref?: any;
   children?: any;
}
export const ALink: React.FC<Props> = ({url, ...props}) => {
 return (
    <NavLink className={'a-link'} {...props} to={url} />
 );
}