import { useState, useEffect, useRef, useImperativeHandle } from 'react';


export interface IModalSettings {
    onEsc?: boolean;
    onBkdrop?: boolean;
    showCloseBtn?: boolean;
}
interface Props {
    ref?: React.ForwardedRef<any>;
    settings?: IModalSettings;
}
interface IOutput {
    display: boolean;
    close: () => void;
    settings: IModalSettings;
}
const initSettings: IModalSettings = {
    onEsc: true,
    onBkdrop: true,
    showCloseBtn: true
}
export const useModalFunctions: (x: Props) => IOutput = ({ ref, settings: ms = {} }) => {
   const settings = useRef<IModalSettings>({...initSettings, ...ms}).current;
   const body = (document.querySelector("body") as HTMLElement);
   const content = (document.getElementById('content-root') as HTMLElement);
   
   const keyCodes = useRef<{ESC: number;}>({ESC: 27}).current;
   const [display, setDisplay] = useState(false);
  

   const reOrganizePage = () => {
       body.style.overflow = 'hidden';
       content.style.transition = 'all 0.3s linear';
       const timeout = setTimeout(() => {
           content.style.filter = 'blur(5px)';
           clearTimeout(timeout);
       }, 50);
   }
   const resetPage = () => {
       body.removeAttribute('style');
       content.removeAttribute('style');
   }
   const open = () => {
      setDisplay(true);
      reOrganizePage();
   }
  
   const close = () => {
      resetPage();
      setDisplay(false);
   }

   const handleClosingKeys = (e: any) => {
       if(e.keyCode == keyCodes.ESC) {
           close();
       }
   }

   useImperativeHandle(ref, () => {
       return {
           open,
           close
       }
   })

   useEffect(() => {
       if(settings.onEsc) document.addEventListener('keydown', handleClosingKeys);
       return () => {
        if(settings.onEsc) document.removeEventListener('keydown', handleClosingKeys);
       }
   },[])
  

  return { display, close, settings }
};