import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSettings } from '../../context';
import { ALink, DonateBtn, GlobeSearchIcon, SearchModal } from '../components';
import { usePageScroll } from '../cus-hook';
import { IModalFunctions } from '../InterfacesOrTypes';
import { Logo } from '../logo';
import { MobileMenu, urls } from '../menus';
import './Header.scss';


export const Header = ({}) => {
    const ref = useRef<IModalFunctions>({} as IModalFunctions)
    const {page: {scroll: {y}}} = useAppSettings();
    const location = useLocation();

    const handleSearchClicked = () => {
        ref.current.open();
    }

    return (
        <PageScroll>
            <header className={`${location.pathname === "/" ? 'fixed-hdr' : ''} ${y > 1 ? 'bg': ''}`}>
                <div className='header-wrapper'>
                    <div className="logo-area">
                        <ALink className='logo-link' url={urls.home}>
                            <Logo />
                            <h1>Leap<span>step</span></h1>
                        </ALink>
                    </div>
                    <div className="btn-area">
                        <div className="search-btn-div">
                        <GlobeSearchIcon clicked={handleSearchClicked} className="white"/>
                        </div>
                        <SearchModal ref={ref} />
                        <DonateBtn />
                        <MobileMenu />
                    </div>
                </div>
            </header>
        </PageScroll>
    )
}


const PageScroll = ({...props}) => {
    const { page: {scroll: {y, setScroll}}} = useAppSettings();
    usePageScroll(setScroll);
    return props.children;
}