import { ColorHeaderCard, CoverContainer, TxtCarousel } from '../../../shared';
import { Testimonial } from './components';
import { testimonies } from './testimonies';
import './Testimonials.scss'


export const Testimonials = () => {
    return (
        <CoverContainer className="testimonials">
            <ColorHeaderCard 
                heading='Our Joy'
                minContent=''
                detailUrl=''>
                    <TxtCarousel>
                        {testimonies.map((v, i) => {
                            return <Testimonial key={i} name={v.name} message={v.message} />
                        })}
                    </TxtCarousel>
            </ColorHeaderCard>
        </CoverContainer>
    );
};