import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import { BsCheck } from "react-icons/bs";
import { BiError } from "react-icons/bi";


export const FbIcon = () => {
    return <FaFacebookF />;
}
export const TwitterIcon = () => {
    return <FaTwitter />
}
export const InstagramIcon = () => {
    return <FaInstagram />;
}

export const CheckmarkIcon = () => {
    return <BsCheck />;
}

export const ErrorIcon = () => {
    return <BiError />;
}