import { NavLink } from "react-router-dom"

interface Props {
    url: string;
}
export const ReadMore: React.FC<Props> = ({url}) => {
    return (
       <div className="read-more-wrapper" style={{textAlign: 'center'}}>
            <NavLink to={url} className="readmore">Read more</NavLink>
       </div>
    )
}