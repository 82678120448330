import { Children, useEffect, useMemo, useRef, useState } from 'react';
import { Arrow } from '../icons';
import styles from './carousel.module.css';
import { CarouselSlideBtn, CarouselSlideIndicators } from './component';

export const Carousel = ({...props}) => {
    const containerRef = useRef<any>();
    const intervalRef = useRef<any>();
    // const pauseTimerRef = useRef<any>();
    const [currentSlide, setCurrentSlide] = useState(0);
    const [transitionX, setTransitionX] = useState(0);

    const { children } = props;
    const chnLen = children ? children.length : 0;
    const hasChn = chnLen > 0;
    

    const slides = useMemo(() => {
        if(!hasChn) return null;

        if(chnLen > 1) {
            const items = Children.map(children, (child, i) => {
                return <li key={i} className={styles.Slide}>{child}</li>
            })
            return [ ...items ];
        }

        return <li className={styles.Slide}>{children}</li>;
    }, [children])


    const moveSlide = (slide: number) => {
        const slider = containerRef.current;
        if(!slider) return;

        // add animation to the sliding
        slider.style.transitionDuration = '400ms';
        if(!slide) slider.style.transitionDuration = '0ms';
        // set which slide to transition to 
        setTransitionX(slider.clientWidth * slide);
        // set the slide number
        setCurrentSlide(slide);
    }

    

    const nextSlide = () => {
        // check for the end of the list
        if(currentSlide === (chnLen - 1)) return moveSlide(0);
        moveSlide(currentSlide + 1);
    }

    const prevSlide = () => {
        // check for the beginning of the list 
        if(!currentSlide) return moveSlide(chnLen - 1);
        moveSlide(currentSlide - 1);
    }

    const closeInterval = () => {
        if(intervalRef.current) return clearInterval(intervalRef.current);
    }

    const handleInterval = (callback: any, timer = 3000) => {
        closeInterval();
        intervalRef.current = setInterval(() => {
            callback();
        }, timer)
    }

    // const handleSlidePause = (slide: number) => {
    //     // get reference to the other interval
    //     // clear the other interval
    //     moveSlide(slide);
    //     closeInterval();
    //     // setTimer for 1 minute
    //     pauseTimerRef.current = setTimeout(() => {
    //         handleInterval(nextSlide);
    //     }, 10000);
    // }

    useEffect(() => {
        handleInterval(nextSlide)
        return () => {
            closeInterval();
        }
    }, [handleInterval])




    // if no children to display then nothing should be showing at all
    if(!children) return null;


    return (
        <div className={styles.Carousel}>
            <ul 
                ref={containerRef} 
                className={styles.Container} 
                style={{transform: `translate3d(-${transitionX}px, 0, 0)`}}>

                { slides }

            </ul>
            <CarouselSlideBtn arrow={<Arrow dir='left'/>} direction={styles.BtnLeft} handle={prevSlide} />
            <CarouselSlideBtn arrow={<Arrow dir='right' />} direction={styles.BtnRight} handle={nextSlide} />
            <CarouselSlideIndicators count={chnLen} slide={currentSlide} handle={moveSlide} />
        </div>
    );
};
