import './GlobeSearchIcon.scss'

interface Props {
    clicked: () => void;
    className?: string
}
export const GlobeSearchIcon: React.FC<Props> = ({clicked, className='regular'}) => {
 return (
    <div onClick={clicked} className={`icon globe-search ${className}`}>
        {/* <div></div> */}
    </div>
 );
}
