import React from "react";
import "./Arrow.scss";

interface Props {
    dir?: string;
    clicked?: () => void; 
    children?: any;
}
export const Arrow: React.FC<Props> = ({dir = "down", clicked, ...props}) => {
    const {children} = props;
    const handle = () => {
        if (!!clicked) {
            clicked();
        }
    }
    return (
        <div className={`icon arrow ${dir}`} onClick={handle}>
            {dir == "down" && children && <div className="des">{children}</div>}
            <span></span>
            {dir !== "down" && children && <div className="des">{children}</div>}
        </div>
    )
}