import { LogoImg } from "./logo-img";

import "./Logo.scss";

export const Logo = () => {
    return (
        <div className="logo">
            <LogoImg />
        </div>
    )
}