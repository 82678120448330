import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPaymentUrl } from '../../../utils';
import './DonateBtn.scss';

export const DonateBtn = ({...props}) => {
    const linkRef = useRef<any>();
    const nav = useNavigate()
    return (
        <div className="donate" ref={linkRef}>
            <a href={getPaymentUrl()} target="_blank"></a>
            <button 
                className={`btn-donate-sm ${props.className}`} 
                onClick={(e) => linkRef.current ? linkRef.current.firstChild.click() : null} 
                type="button">{props.label || 'Donate'}
            </button>
        </div>
        // <div className="donate">
        //     <button 
        //         className={`btn-donate-sm ${props.className}`} 
        //         onClick={() => nav(getPaymentUrl())} 
        //         type="button">{props.label || 'Donate'}
        //     </button>
        // </div>
    );
}
