import React, { useEffect, useState } from 'react';
import { checkFormRequiredValidity } from '../utils';
import { Field, IField, IInputResponse } from './field';
import './Form.scss'


export interface IFormSettings {
    showBtn?: boolean;
    btnLabel?: string;
    vertical?: boolean;
    clr?: string;
    canSubmit?: boolean;
}

interface IObject {
    [x:string]: any;
}

export interface IForm {
    formName: string;
    fields: IField[];
    init?: IObject;
    onSubmit?: (values: any) => void;
    settings?: IFormSettings;
    children?: any;
}

const defaultSettings: IFormSettings = {showBtn: true, btnLabel: 'Submit', vertical: true, canSubmit: true, clr: 'primary'};

export const Form: React.FC<IForm> = ({
    formName, 
    fields,
    init = {},
    onSubmit = () => null, 
    settings: ss = {} as IFormSettings,
    children
}) =>{
    const [settings] = useState({...defaultSettings, ...ss});

    const [values, setValues] = useState({});
    const [error, setError] = useState('');
    const [canSubmit, setCanSubmit] = useState(settings.canSubmit);
    

    const handle = (v: IInputResponse) => {
        setValues(x => ({...x, [v.name]: v.value}));
    }

    const handleSubmit = () => {
        setError('');
        const results = checkFormRequiredValidity(values, fields);
        if(results.length > 0) return setError(results[0]);
        onSubmit(values);
    }
    
    useEffect(() => {
        const keys = Object.keys(values);
        setValues(state => ({...state, ...keys.reduce((prev, cur) => {
            (prev as any)[cur] = init ? (init as any)[cur] : "";
            return prev;
        }, {})}))
    }, [init])


    return (
        <form className={`form ${formName.toLowerCase()}-form ${settings.vertical ? 'vertical' : ''}`}>
            <h2>{(formName as any).capitalizeFirstLetter()} Form</h2>
            {error && <p style={{color: 'red'}}>{error}</p>}
            <>{children}</>
            {fields.map((field, idx) => {
               return <Field formName={formName.toLowerCase()} key={idx} field={field} init={(values as any)[field.name] || ""} changeHandler={handle}/>;
            })}

            {settings.showBtn && (
                <div className="form-btn-div">
                    <button type="button" disabled={!canSubmit} className={`${settings.clr}`} onClick={handleSubmit}>{settings.btnLabel}</button>
                </div>
            )}
        </form>
    )
}