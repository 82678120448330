import "./Footer.scss";


import {QuickLinks, Reg} from "./components";

export const Footer = () => {
    return (
        <footer>
            <QuickLinks />
           <Reg />
        </footer>
    )
}