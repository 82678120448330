import React, { useRef, useState } from 'react';
import { Close, GlobeSearchIcon } from '../icons';
import './Search.scss'


interface Props {
    show?: boolean;
}
export const Search: React.FC<Props> = ({show:init = true}) => {
    const keyCode = useRef({enter: 13}).current
    const [show, setShow] = useState(init);
    const [value, setValue] = useState('')
    const handleChange = (e: any) => {
        setValue(e.target.value);
    }
    const handleEnter = (e: any) => {
        if(e.keyCode === keyCode.enter) {
            setValue('You pressed enter');
        }
    }
    const handleClear = () => {
        setValue('');
    }
    return (
       <div className={`search ${show ? 'active' : ''}`}>
            <GlobeSearchIcon clicked={() => init ? null : setShow(!show)} className="black nh" />
           <div className="input-div">
                <input 
                    value={value} 
                    onChange={handleChange} 
                    onKeyDown={handleEnter}
                    type="text" 
                    placeholder='Search' autoFocus />
           </div>
           <div className='clear'>
            {value && <Close clicked={handleClear}/>}
           </div>
       </div>
    );
}